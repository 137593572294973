@import "mixins";

.#{$prefix}button {
  @include button-configs;
}

a.#{$prefix}button {
  font-weight: var(--button-font-weight);
  text-decoration: none;
}
